<template>
  <div class="GlobalTable">
    <!-- 胶囊切换 -->
    <div class="button_top">
      <div class="faultBtn">
        <div :class="type === '00' ? 'active' : 'none'" @click="btnClick('00')">
          已签收待收货<span v-if="type === '00'">({{ signNum || 0 }})</span>
        </div>
        <div
          :class="type === '001' ? 'active' : 'none'"
          @click="btnClick('001')"
        >
          签收失败<span v-if="type === '001'">({{ signFailNum || 0 }})</span>
        </div>
        <div :class="type === '01' ? 'active' : 'none'" @click="btnClick('01')">
          暂停收货中<span v-if="type === '01'">({{ pauseNum || 0 }})</span>
        </div>
        <div :class="type === '02' ? 'active' : 'none'" @click="btnClick('02')">
          退机中<span v-if="type === '02'">({{ backNum || 0 }})</span>
        </div>
        <div :class="type === '03' ? 'active' : 'none'" @click="btnClick('03')">
          已完成收货<span v-if="type === '03'">({{ finishNum || 0 }})</span>
        </div>
      </div>
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-if="refresh"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 订单号 -->
      <el-table-column
        label="订单号"
        align="center"
        slot="orderNo"
        width="130px"
      >
        <template v-slot="{ row }">
          <a
            v-if="row.orderNo !== '--'"
            :href="`/RecallOrder/Details?type=edit&id=${row.orderNo}`"
            target="_blank"
            style="text-decoration: underline; color: #0981ff"
          >
            {{ row.orderNo }}
          </a>
          <span v-else>--</span>
          <p v-if="row.imei">串号:{{ row.imei }}</p>
        </template>
      </el-table-column>
      <!-- 机型 -->
      <el-table-column
        label="机型"
        align="center"
        slot="phoneName"
        width="100px"
      >
        <template v-slot="{ row }">
          <span>{{ row.phoneName || "--" }}</span>
        </template>
      </el-table-column>
      <!-- 成交天数 -->
      <el-table-column label="成交天数" align="center" slot="afterAckDays">
        <template v-slot="{ row }">
          <span v-if="row.ackDays !== '--'" style="color: red"
            >{{ row.ackDays }}天</span
          >
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column slot="state" label="订单状态" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.orderState === '00'">待确认</span>
          <span v-else-if="row.orderState === '01'">已确认</span>
          <span v-else-if="row.orderState === '02'">待支付</span>
          <span v-else-if="row.orderState === '03'">已绑码</span>
          <span v-else-if="row.orderState === '04'">已收货</span>
          <span v-else-if="row.orderState === '05'">降价收货</span>
          <span v-else-if="row.orderState === '10'">已取消</span>
          <span v-else-if="row.orderState === '20'">已作废</span>
          <span v-else-if="row.orderState === '30'">已退回</span>
        </template>
      </el-table-column>
      <!-- 签收渠道 -->
      <el-table-column
        label="签收渠道"
        align="center"
        slot="signChannel"
        width="120"
      >
        <template v-slot="{ row }">
          <span>
            {{
              row.signChannel == "00"
                ? "平台后台"
                : row.signChannel == "01"
                ? "回收商后台"
                : row.signChannel == "02"
                ? "回收小程序"
                : row.signChannel == "03"
                ? "仓库后台"
                : "采货侠调用"
            }}
          </span>
        </template>
      </el-table-column>
      <!-- 签收时间 -->
      <el-table-column
        label="签收时间"
        v-if="type !== '001'"
        align="center"
        slot="signTime"
      >
        <template v-slot="{ row }">
          <span>{{ row.signTime || "--" }}</span>
        </template>
      </el-table-column>
      <!-- 签收失败时间 -->
      <el-table-column
        label="签收失败时间"
        v-if="type == '001'"
        align="center"
        slot="signFailTime"
      >
        <template v-slot="{ row }">
          <span>{{ row.signFailTime || "--" }}</span>
        </template>
      </el-table-column>

      <!-- 是否入库 -->
      <el-table-column
        label="是否入库"
        v-if="type == '00'"
        align="center"
        slot="staffWaitSendNum"
      >
        <template v-slot="{ row }">
          <span style="color: red">
            {{ row.isInto == "00" ? "是" : "否" }}
          </span>
          <p v-if="row.isInto == '00'">{{ row.intoWarehouseName || "--" }}</p>
        </template>
      </el-table-column>
      <!-- 入库异常标记 -->
      <el-table-column
        v-if="type == '00'"
        label="入库异常标记"
        align="center"
        width="100px"
        slot="storeWaitSendNum"
      >
        <template v-slot="{ row }">
          <span v-if="row.isInto == '01'" style="color: red"
            >超{{ row.intoFlag.numValue }}天未入库</span
          >
          <p v-else>--</p>
        </template>
      </el-table-column>
      <!-- 出库时间 -->
      <el-table-column
        label="出库时间"
        v-if="type == '00'"
        align="center"
        slot="handlerUseDays"
      >
        <template v-slot="{ row }">
          <div v-if="row.outFlag != null">
            <span
              style="color: red"
              v-if="row.outFlag.numValue < row.outFlag.splitValue[0]"
              >出库不足{{ row.outFlag.numValue }}天</span
            >
            <span v-else>
              <p v-if="row.outFlag.numValue != null">
                已出库{{ row.outFlag.numValue }}天
              </p>
              <p>--</p>
            </span>
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <!-- 签收操作人 -->
      <el-table-column
        v-if="type == '00'"
        label="签收操作人"
        align="center"
        slot="errorTrackFollowCount"
        width="120"
      >
        <template v-slot="{ row }">
          <span>{{ row.signer || "--" }}</span>
        </template>
      </el-table-column>
      <!-- X天后完结收货 -->
      <el-table-column
        v-if="type == '00'"
        label="X天后完结收货"
        align="center"
        slot="errorTrackFollowContent"
        width="120"
      >
      <template v-slot="{ row }">
          <div class="noteWrap" v-if="row.finishFlag!=null">
             <p v-if="row.finishFlag.numValue!=null">
              {{ Math.floor(row.finishFlag.numValue/24) }}天
              {{ row.finishFlag.numValue%24 }}小时
            </p>
             <p v-else>--</p>
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <!-- 状态更新时间 -->
      <el-table-column
        label="状态更新时间"
        v-if="type !== '00'"
        align="center"
        slot="failTime"
      >
        <template v-slot="{ row }">
          <span>{{ row.updateTime }}</span>
        </template>
      </el-table-column>
      <!-- 操作 -->
      <el-table-column
        v-if="type == '001'"
        fixed="right"
        label="操作"
        width="110px"
        align="center"
        slot="action"
      >
        <template v-slot="{ row }">
          <el-button
            type="danger"
            round
            size="mini"
            @click="actionClick('重新发起', row)"
            >重新发起</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 完结收货 -->
    <el-dialog
      title="操作提示"
      :visible.sync="traceLogShow"
      width="500px"
      @close="overClose"
    >
      <div class="offline_box">
        <p>是否确认完结收货？</p>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="remarckruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="完结收货备注"
          prop="finishNote"
          label-width="110px"
        >
          <el-input
            type="textarea"
            maxlength="200"
            v-model="ruleForm.finishNote"
            placeholder="请输入备注内容，最多200字"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="traceLogShow = false">关 闭</el-button>
        <el-button type="primary" @click="getFollow('remarckruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--  重新发起 -->
    <GlobalDialog
      @onClose="expediteGoodsShow = false"
      @resetPopupData="expediteGoodsShow = false"
      :show="expediteGoodsShow"
      title="操作提示"
      top="6%"
      :width="500"
      @submitPopupData="expediteGoodsConfig"
    >
      <div class="offline_box">
        <p>是否确认重新发起暂停收货？</p>
      </div>
    </GlobalDialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Table",
  components: {
  },
  data() {
    return {
      ruleForm: {
        finishNote: "",
      },
      rules: {
        finishNote: [
          { required: true, message: "请输入收货备注", trigger: "blur" },
        ],
      },
      refresh: false,
      finishNum: "",
      backNum: "",
      pauseNum: "",
      signNum: "",
      signFailNum: "",
      currentData: {},
      tableColumns: [
        { slotName: "orderNo" }, //	订单号
        {
          prop: "companyName",
          label: "门店商家",
        },
        {
          prop: "outOrderNo",
          label: "外部单号",
          width: "130",
        },
        { slotName: "phoneName" }, //机型
        {
          prop: "merchantName",
          label: "回收商家",
        },
        {
          prop: "receiverMerchantName",
          label: "收货方",
          width: "155",
        },
        { slotName: "afterAckDays" }, //	成交天数
        {
          prop: "expressTime",
          label: "发货时间",
        },
        {
          prop: "expressNo",
          label: "发货单号",
          width: "140",
        },
        { slotName: "state" },
        { slotName: "signChannel" },
        { slotName: "signTime" }, //签收时间
        { slotName: "signFailTime" }, //签收失败时间
        { slotName: "staffWaitSendNum" }, //	是否入库
        { slotName: "storeWaitSendNum" }, //	入库异常标记
        { slotName: "handlerUseDays" }, //出库时间
        { slotName: "errorTrackFollowCount" }, //	签收操作人
        { slotName: "errorTrackFollowContent" }, //	X天后完结收货
        { slotName: "failTime" }, //状态更新时间
        { slotName: "action" }, //操作
      ],
      type: "00",
      seachDataList: [],
      loading: false,
      excelLoading: false,
      allExcelLoading: false,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      traceLogShow: false, //完结收货
      command: "", // 谷歌验证码
      expediteGoodsShow: false, //重新发起
    };
  },
  created() {
    this.refresh = true;
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    overClose() {
      this.$refs["remarckruleForm"].resetFields();
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        ackStartTime: this.SeachParams.ackStartTime,
        ackEndTime: this.SeachParams.ackEndTime,
        updateEndTime: this.SeachParams.updateEndTime,
        updateStartTime: this.SeachParams.updateStartTime,
        expressNo: this.SeachParams.expressNo,
        imei: this.SeachParams.imei,
        manualMerchantId: this.SeachParams.manualMerchantId,
        companyId: this.SeachParams.companyId,
        orderNo: this.SeachParams.orderNo,
        outOrderNo: this.SeachParams.outOrderNo,
        orderState: this.SeachParams.orderState,
        isInto: this.SeachParams.isInto,
        isOut: this.SeachParams.isOut,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        state: this.type, // 	状态(00：已签收待收货，001：签收失败，01：暂停收货中，02：退机中，03：已完成收货)
      };
      this.loading = true;
      _api
        .getChxtakeList(baseRequest)
        .then((res) => {
          if (res.code === 1) {
            console.log("~~~", res.data);
            this.seachDataList = res.data.list.records;
            this.finishNum = res.data.finishNum;
            this.backNum = res.data.backNum;
            this.pauseNum = res.data.pauseNum;
            this.signNum = res.data.signNum;
            this.signFailNum = res.data.signFailNum;
            this.page.total = res.data.list.total;
            this.page.pageNum = res.data.list.current;
          }
        })
        .finally(() => {
          this.loading = false;
          if (typeof this.SeachParams.disuseLoding === "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    // 胶囊按钮切换
    btnClick(type) {
      if (this.type == type || this.loading) {
        return false;
      }
      this.seachDataList = [];
      this.refresh = false;
      this.type = type;
      this.$store.commit("tagsView/GOODSTRACKSTATE", type);
      this.page.pageNum = 1;
      this.refresh = true;
      this.$forceUpdate();
    },
    actionClick(type, row) {
      console.log("@@@", row);
      this.currentData = row;
      switch (type) {
        case "重新发起":
          this.expediteGoodsShow = true;
          break;
      }
    },
    getFollow(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api
            .gethaihiushoufinish({
              orderNo: this.currentData.orderNo,
              finishNote: this.ruleForm.finishNote,
            })
            .then((res) => {
              this.$message.success("操作成功");
              this.traceLogShow = false;
              this.handleCurrentChange();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重新发起
    expediteGoodsConfig() {
      _api
        .renREWchx({
          orderNo: this.currentData.orderNo,
        })
        .then((r) => {
          if (r.code) {
            this.$message.success("操作成功");
            // 关闭弹窗
            this.expediteGoodsShow = false;
            // 重拉请求
            this.handleCurrentChange(1);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/ .el-button {
      margin-left: 0;
    }
  }

  .button_top {
    margin-bottom: 20px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    min-width: 800px;
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981ff;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  .verify-title {
    color: #0981ff;
    line-height: 16px;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }

  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .font-w {
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-as {
    display: flex;
    align-items: flex-start;
  }
}

.logStyle {
  border: 1px solid #5a8eff;
  width: 590px;
  min-height: 50px;
  max-height: 400px;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 6px;
}

.noteWrap {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.offline_box {
  width: 100%;

  > h2 {
    color: #ff687b;
    font-size: 12px;
    font-weight: 900;
  }

  > p {
    font-size: 14px;
    margin: 20px 0;
    color: #333;
    text-align: center;
  }
  .cancel_offline_note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f2f2f2;
    height: 55px;
  }
}

.remarks_box {
  width: 100%;

  > h2 {
    color: #666;
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.apply_for_tip {
  margin-bottom: 20px;
  transform: translateX(-10px);
}

/deep/ .apply_for_box .el-dialog .el-dialog__body {
  padding-left: 30px;
  padding-right: 30px;
}

.enable_box {
  /deep/ .el-switch__label * {
    line-height: 1;
    font-size: 12px;
    display: inline-block;
  }

  /deep/ .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
    font-size: 12px !important;
  }

  /*打开时文字位置设置*/
  /deep/ .el-switch__label--right {
    z-index: 1;
    right: 6px;
  }

  /*关闭时文字位置设置*/
  /deep/ .el-switch__label--left {
    z-index: 1;
    left: 6px;
  }

  /*显示文字*/
  /deep/ .el-switch__label.is-active {
    display: block;
  }

  /*开关宽度*/
  /deep/ .el-switch .el-switch__core,
  /deep/ .el-switch .el-switch__label {
    width: 50px !important;
  }

  /deep/ .el-switch__core {
    background: #b6b9d0;
  }

  /deep/ .el-switch.is-checked .el-switch__core {
    background-color: #0981ff;
  }
}

.logistics-switch-dialog {
  /deep/ .header_messgae {
    margin-bottom: 34px;
    color: #ff7586;
  }

  /deep/ .tip {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 33px;
  }
}
.blue_text {
  cursor: pointer;
  color: rgb(9, 129, 255);
  text-decoration: underline;
}
</style>
